const MESSAGE_SELECTOR = '.js-gift-message'
const MESSAGE_SUCCESS_SELECTOR = '.js-gift-message-success'
const OPEN_CLASS = 'open'
const CLOSE_SUCCESS_CLASS = 'close-edit'
const MESSAGE_MODAL_SELECTOR = '.js-cart-gift-message-modal'
const MESSAGE_BUTTON_SELECTOR = '.js-gift-message-button'
const SUBMIT_BUTTON_SELECTOR = '.js-gift-message-btn-submit'

class CartGift extends HTMLElement {
  constructor() {
    super();
    this.giftMessageEl = this.querySelector(MESSAGE_SELECTOR)
    this.btnSubmit = this.querySelector(SUBMIT_BUTTON_SELECTOR)
    this.giftMessageSuccessEl = this.querySelector(MESSAGE_SUCCESS_SELECTOR)
    this.sendSuccess = false
    this.giftMessage = this.giftMessageEl ? this.giftMessageEl.value : ''
    this.toggleButtonEl = this.querySelector(MESSAGE_BUTTON_SELECTOR)

    if (this.giftMessageEl) {
      this.checkButtonSubmit()
      this.giftMessageEl.addEventListener('input', () => {
        this.checkButtonSubmit()
      })
    }
  }

  checkButtonSubmit() {
    if (this.giftMessageEl.value === this.giftMessage) {
      this.btnSubmit.setAttribute('disabled', 'disabled')
    } else {
      this.giftMessage = this.giftMessageEl.value
      this.btnSubmit.removeAttribute('disabled')
    }
  }

  addGiftMessage() {
    const formData = {
      note: this.giftMessageEl.value
    }
    fetch('/cart/update.js', {
      method: 'post',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(this.onCartUpdate.bind(this))
  }

  onCartUpdate (cartResponse) {
    cartResponse.json().then((data) => {
      if (data) {
        this.sendSuccess = true
        this.giftMessageSuccessEl.innerHTML = this.giftMessageEl.value
        this.toggleGiftMessageSuccessText(true)
        setTimeout(this.toggleGiftMessageModal.bind(this), 100)
        setTimeout(this.toggleGiftMessageSuccessText(true), 100)
      } else {
        console.log('response', data)
      }
    })
  }

  toggleGiftMessageModal() {
    const messageModalSelectors = Array.prototype.slice.call(this.querySelectorAll(MESSAGE_MODAL_SELECTOR))

    messageModalSelectors.map(el => {
      if (el.classList.contains(OPEN_CLASS)) {
        el.classList.remove(OPEN_CLASS)
        this.giftMessageEl.classList.add('hidden')
        this.toggleButtonEl && this.toggleButtonEl.classList.remove(OPEN_CLASS)
        if (this.sendSuccess) {
          this.toggleGiftMessageSuccessText(true)
        }
      } else {
        el.classList.add(OPEN_CLASS)
        this.toggleButtonEl && this.toggleButtonEl.classList.add(OPEN_CLASS)
        this.giftMessageSuccessEl.classList.add('hidden')
        this.giftMessageEl.classList.remove('hidden')
        if (this.sendSuccess) {
          this.toggleGiftMessageSuccessText(false)
        }
      }
    })
  }

  toggleGiftMessageSuccessText(isShow) {
    if (isShow) {
      this.giftMessageEl.classList.add('hidden')
      this.giftMessageSuccessEl.classList.remove('hidden')
      this.toggleButtonEl && this.toggleButtonEl.classList.add(CLOSE_SUCCESS_CLASS)
    } else {
      this.giftMessageEl.classList.remove('hidden')
      this.giftMessageSuccessEl.classList.add('hidden')
      this.toggleButtonEl && this.toggleButtonEl.classList.remove(CLOSE_SUCCESS_CLASS)
    }
  }
}

customElements.define('cart-gift', CartGift)
