import SwiperWrapper from '@/scripts/swiper-wrapper.js'

const parseJSON = string => {
  let json = {}
  string = string.replace(/<!--.*-->/g, '')
  try {
    json = JSON.parse(string)
  } catch (e) {
    console.log(e)
  }
  return json
}

class PredictiveSearch extends HTMLElement {
  constructor() {
    super()
    this.cachedResults = {}
    this.input = this.querySelector('input[type="text"]')
    this.predictiveSearchWrapper = this.querySelector('[data-predictive-search]')
    this.predictiveSearchResults = this.querySelector('[data-predictive-search-results]')
    this.inputType = this.querySelector('[data-input-anchor-type]')
    this.searchType = this.dataset.searchType
    this.isOpen = false
    this.latestQuery = ''
    this.productSwiper = null
    this.formEl = this.querySelector('form.search')

    this.setupEventListeners()
  }

  setupEventListeners() {
    this.formEl.addEventListener('submit', this.onFormSubmit.bind(this))

    this.input.addEventListener('input', (event) => {
      this.onChange(event)
    })
    this.input.addEventListener('focus', this.onFocus.bind(this))
    // this.addEventListener('focusout', this.onFocusOut.bind(this))
    this.addEventListener('keyup', this.onKeyup.bind(this))
    this.addEventListener('keydown', this.onKeydown.bind(this))
  }

  getQuery() {
    return this.input.value.trim()
  }

  onChange() {
    const content = this.querySelector('.js-data-predictive-search')
    content.classList.remove('hidden')
    const searchTerm = this.getQuery()

    if (!searchTerm.length) {
      this.close(true)
      return
    }

    this.getSearchResults(searchTerm)
  }

  onFormSubmit(event) {
    if (!this.getQuery().length || this.querySelector('[aria-selected="true"] a')) event.preventDefault()
  }

  onFocus() {
    const searchTerm = this.getQuery()

    if (!searchTerm.length) return

    if (this.getAttribute('results') === 'true') {
      this.open()
    } else {
      this.getSearchResults(searchTerm)
    }
  }

  onFocusOut() {
    setTimeout(() => {
      if (!this.contains(document.activeElement)) this.close()
    })
  }

  onKeyup(event) {
    if (!this.getQuery().length) this.close(true)
    event.preventDefault()

    switch (event.code) {
      case 'ArrowUp':
        this.switchOption('up')
        break
      case 'ArrowDown':
        this.switchOption('down')
        break
      case 'Enter':
        this.selectOption()
        break
    }
  }

  onKeydown(event) {
    // Prevent the cursor from moving in the input when using the up and down arrow keys
    if (
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault()
    }
  }

  switchOption(direction) {
    if (!this.getAttribute('open')) return

    const moveUp = direction === 'up'
    const selectedElement = this.querySelector('[aria-selected="true"]')
    const allElements = this.querySelectorAll('li')
    let activeElement = this.querySelector('li')

    if (moveUp && !selectedElement) return

    this.statusElement.textContent = ''

    if (!moveUp && selectedElement) {
      activeElement = selectedElement.nextElementSibling || allElements[0]
    } else if (moveUp) {
      activeElement = selectedElement.previousElementSibling || allElements[allElements.length - 1]
    }

    if (activeElement === selectedElement) return

    activeElement.setAttribute('aria-selected', true)
    if (selectedElement) selectedElement.setAttribute('aria-selected', false)

    this.setLiveRegionText(activeElement.textContent)
    this.input.setAttribute('aria-activedescendant', activeElement.id)
  }

  selectOption() {
    const selectedProduct = this.querySelector('[aria-selected="true"] a, [aria-selected="true"] button')

    if (selectedProduct) selectedProduct.click()
  }

  async fetchSearchResults(query) {
    const response = await fetch(`${window.BARREL.routes.predictive_search_url}?${query}`)
    if (!response.ok) {
      const error = new Error(response.status.toString())
      this.close()
      throw error
    }

    return response.text()
  }

  async getSearchResults(searchTerm) {
    const queryKey = searchTerm.replace(" ", "-").toLowerCase()
    this.latestQuery = searchTerm

    this.setLiveRegionLoadingState()

    if (this.cachedResults[queryKey]) {
      this.renderSearchResults(this.cachedResults[queryKey], searchTerm)
      return
    }

    const searchAllQuery = new URLSearchParams(
      Object.entries({
        'q': searchTerm,
        'section_id': 'predictive-search',
        'resources[type]': this.searchType,
        'resources[options][fields]': 'title,product_type,variants.title,variants.sku,vendor,body,tag,author',
        'resources[limit_scope]': 'each'
      })
    )

    // const searchProductQuery = new URLSearchParams(
    //   Object.entries({
    //     'q': searchTerm,
    //     'section_id': 'predictive-search',
    //     'resources[type]': 'product',
    //     'resources[options][fields]': 'title,product_type,variants.title,variants.sku,vendor',
    //     'resources[limit]': '50'
    //   })
    // )

    // const searchPageQuery = new URLSearchParams(
    //   Object.entries({
    //     'q': searchTerm,
    //     'section_id': 'predictive-search',
    //     'resources[type]': 'page',
    //     'resources[options][fields]': 'title',
    //     'resources[limit]': '50'
    //   })
    // )

    // const searchArticleQuery = new URLSearchParams(
    //   Object.entries({
    //     'q': searchTerm,
    //     'section_id': 'predictive-search',
    //     'resources[type]': 'article',
    //     'resources[options][fields]': 'title',
    //     'resources[limit]': '50'
    //   })
    // )

    try {
      const results = await Promise.all([this.fetchSearchResults(searchAllQuery)])
        this.cachedResults[queryKey] = results

        if (this.latestQuery === searchTerm) {
          this.renderSearchResults(results, searchTerm)
        }

      // let results = await Promise.all([
        // this.fetchSearchResults(searchProductQuery),
        // this.fetchSearchResults(searchPageQuery),
        // this.fetchSearchResults(searchArticleQuery)
      // ])
    } catch (error) {
      this.close()
      throw error
    }
  }

  setLiveRegionLoadingState() {
    this.statusElement = this.statusElement || this.querySelector('.predictive-search-status')
    this.loadingText = this.loadingText || this.getAttribute('data-loading-text')

    this.setLiveRegionText(this.loadingText)
    this.setAttribute('loading', true)
  }

  setLiveRegionText(statusText) {
    this.statusElement.setAttribute('aria-hidden', 'false')
    this.statusElement.textContent = statusText

    setTimeout(() => {
      this.statusElement.setAttribute('aria-hidden', 'true')
    }, 1000)
  }

  renderSearchResults(responseTexts, searchTerm) {
    if (!this.parser) {
      this.parser = new DOMParser()
    }

    const resultsElements = responseTexts
      .map(text => this.parser.parseFromString(text, 'text/html').querySelector('.predictive-search-results'))
      .filter(Boolean)

    if (resultsElements.length) {
      this.predictiveSearchResults.replaceChildren(...resultsElements)
    }

    this.getTypesTotal(searchTerm)
    this.setAttribute('results', true)

    this.setLiveRegionResults()
    this.open()

    const buttonViewAlls = this.querySelectorAll('[data-button-view-all]')
    buttonViewAlls.length ? buttonViewAlls.forEach(button => {
      button.addEventListener('click', (event) => {
        event.preventDefault()
        this.updateAnchorValue(button, this.formEl)
      })
    }) : ''
  }

  getTypesTotal(searchTerm) {
    fetch(`/search?view=header-search&q=${encodeURIComponent(searchTerm)}&type=product,article`)
      .then((response) => response.text())
      .then(responseText => {
        const res = parseJSON(responseText)
        const productCountEl = this.querySelector('.js-ps-product-count')
        const articleCountEl = this.querySelector('.js-ps-article-count')
        if (productCountEl) {
          productCountEl.innerHTML = res && res.product_count ? `(${res.product_count})` : '(0)'
        }
        if (articleCountEl) {
          articleCountEl.innerHTML = res && res.article_count ? `(${res.article_count})` : '(0)'
        }
      })
  }

  setLiveRegionResults() {
    this.removeAttribute('loading')
    const predictiveSearch = this.querySelector('[data-predictive-search-live-region-count-value]')
    if (predictiveSearch) {
      this.setLiveRegionText(predictiveSearch.textContent)
    }
  }

  getResultsMaxHeight() {
    const heightHeader = document.getElementById('shopify-section-header') ?
      document.getElementById('shopify-section-header').getBoundingClientRect().bottom :
      0
    this.resultsMaxHeight = window.innerHeight - heightHeader
    return this.resultsMaxHeight
  }

  open() {
    // this.predictiveSearchWrapper.style.maxHeight = this.resultsMaxHeight || `${this.getResultsMaxHeight()}px`
    this.setAttribute('open', true)
    this.input.setAttribute('aria-expanded', true)
    this.isOpen = true
    this.predictiveSearchWrapper.hidden = false
    this.initProductSwiper()
  }

  initProductSwiper() {
    const swiperWrapper = this.querySelector('.js-swiper')
    if (swiperWrapper) {
      this.destroyProductSwiper()
      const options = {
        slidesPerView: 2.48,
        cssMode: false,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        breakpoints: {
          320: {
            slidesPerView: 2.48,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 18
          }
        }
      }
      this.productSwiper = new SwiperWrapper(swiperWrapper, options)
    }
  }

  destroyProductSwiper() {
    if (this.productSwiper) {
      this.productSwiper.swiper.destroy(true, true)
      this.productSwiper = null
    }
  }

  close(clearSearchTerm = false) {
    if (clearSearchTerm) {
      this.input.value = ''
      this.removeAttribute('results')
    }

    const selected = this.querySelector('[aria-selected="true"]')

    if (selected) selected.setAttribute('aria-selected', false)

    this.input.setAttribute('aria-activedescendant', '')
    this.removeAttribute('open')
    this.input.setAttribute('aria-expanded', false)
    this.resultsMaxHeight = false
    this.predictiveSearchWrapper.removeAttribute('style')
    this.predictiveSearchWrapper.hidden = true

    this.isOpen = false
    this.destroyProductSwiper()
  }

  updateAnchorValue(button, form) {
    const type =  button.getAttribute('data-type')
    if (type && this.inputType) {
      this.inputType.value = type
    }
    form.submit()
  }
}

customElements.define('predictive-search', PredictiveSearch)
