import { debounce } from "lodash"

class QuantityInput extends HTMLElement {
  constructor () {
    super()

    this.addDataLayerRemoveFormCart = this.addDataLayerRemoveFormCart.bind(this)
    const input = this.querySelector('input[type="number"]')
    const plusButton = this.querySelector('button[name="plus"]')
    const minusButton = this.querySelector('button[name="minus"]')
    const min = parseInt(input.getAttribute('min'), 10)

    input.addEventListener('change', debounce(() => {
      // Dispatch "change-quantity" event
      input.dispatchEvent(new Event('change-quantity', { bubbles: true }))
      if (input.value === 0) {
        this.addDataLayerRemoveFormCart()
      }
    }, 50))

    plusButton.addEventListener('click', debounce(() => {
      // Increment input value and dispatch change-quantity event
      input.value = parseInt(input.value, 10) + parseInt(input.step, 10)
      input.setAttribute('data-trigger', 'plus')
      input.dispatchEvent(new Event('change-quantity', { bubbles: true }))
      input.value > 0 && minusButton.classList.remove('disable')
    }, 50))

    minusButton.addEventListener('click', debounce(() => {
      // Decrement input value and dispatch change-quantity event
      input.value = Math.max(parseInt(input.value, 10) - parseInt(input.step, 10), min)
      input.setAttribute('data-trigger', 'minus')
      input.dispatchEvent(new Event('change-quantity', { bubbles: true }))
      input.value == 0 && minusButton.classList.add('disable')
      this.addDataLayerRemoveFormCart(input.value)
    }, 50))
  }

  addDataLayerRemoveFormCart () {
    const cartItem = this.closest('cart-item')
    const cartItemRemove = cartItem ? cartItem.querySelector('cart-item-remove') : ''
    if (cartItemRemove) {
      cartItemRemove.addDatalayer()
    }
  }
}

if (!customElements.get('quantity-input')) customElements.define('quantity-input', QuantityInput)
