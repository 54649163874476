let __cache = {}
const modules = import.meta.glob(['../../modules/**/*.js', '../../modules/**/*.vue.js'])

const errors = {
  duplicate ([id, ...args]) {
    return [
      `a duplicate key ${id} was found in the cache. This instance will be overwritten.`,
      ...args
    ]
  },
  undefined ([id, ...args]) {
    return [
      `can't find ${id} in the cache`,
      ...args
    ]
  },
  error ([id, ...args]) {
    return [
      `${id} threw an error\n\n`,
      ...args
    ]
  }
}

function log (level, type, ...args) {
  console[level]('⚙️ micromanager -', ...errors[type](args))
}

function getModuleName (node) {
  return node.getAttribute('data-module') || ''
}

function getVueName (node) {
  return (node.getAttribute('is') || '').replace('vue-', '')
}

function requireVueModule (vueName = '') {
  if (vueName) {
    return modules[`../../modules/${vueName}/${vueName}.vue.js`]()
  }
}

export function visible({ element }) {
  return new Promise(function (resolve) {
    const observer = new window.IntersectionObserver(async function (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          observer.disconnect()
          resolve(true)
          break
        }
      }
    })
    observer.observe(element)
  })
}

export function idle () {
  return new Promise(function (resolve) {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(resolve)
    } else {
      setTimeout(resolve, 200)
    }
  })
}

export function media({ query }) {
  const mediaQuery = window.matchMedia(query)
  return new Promise(function (resolve) {
    if (mediaQuery.matches) {
      resolve(true)
    } else {
      mediaQuery.addEventListener('change', resolve, { once: true })
    }
  })
}

async function requireModule (moduleName = '', node) {
  if (moduleName) {
    if (node.hasAttribute('data-client-ready')) {

    } else if (node.hasAttribute('data-client-idle')) {
      await idle()
    } else if (node.hasAttribute('data-client-media')) {
      const clientMedia = node.getAttribute('data-client-media')

      if (clientMedia) {
        await media({ query: clientMedia })
      }
    } else {
      await visible({ element: node })
    }

    await loadVueModules(node)
    const { default: fn } = await modules[`../../modules/${moduleName}/${moduleName}.js`]()
    if (moduleName === 'carousel-swiper') {
      const event = new CustomEvent('refreshAOS')
      document.body.dispatchEvent(event)
    }

    return {
      fn: fn ? fn.bind(null, node) : () => {},
      name: moduleName
    }
  }
}

function getNodes (ctx, selector) {
  return [].slice.call(ctx.querySelectorAll(selector))
}

export async function loadVueModules (ctx = document) {
  const nodes = getNodes(ctx, '[is]')

  for (let i = 0; i < nodes.length; i++) {
    await requireVueModule(getVueName(nodes[i]))
  }
}

function init (types, ctx = document) {
  return {
    cache: {
      set (id, instance) {
        if (__cache[id]) log('warn', 'duplicate', id)
        __cache[id] = instance
      },
      get (id) {
        try {
          return __cache[id]
        } catch (e) {
          log('warn', 'undefined', id)
          return null
        }
      },
      dump () {
        return __cache
      }
    },
    async mount () {
      const modules = []
      const appModules = []

      const callModule = m => {
        const instance = m.fn()

        if (instance) {
          this.cache.set(m.name, instance)
        }

        return instance
      }

      const loadModules = selector => {
        getNodes(ctx, selector)
          .filter(getModuleName)
          .map(async node => {
            const instance = await requireModule(getModuleName(node), node)
            node.removeAttribute('data-module')
            callModule(instance)
          })
      }

      loadModules('[data-vue-app]')
      loadModules('[data-module]')

      return this
    },
    unmount () {
      for (let key in __cache) {
        const instance = __cache[key]
        if (instance.unmount) {
          instance.unmount()
          delete __cache[key]
        }
      }

      return this
    }
  }
}

export default init
