import Swiper, { Navigation, Pagination, EffectFade, Autoplay, Thumbs, Scrollbar, Mousewheel } from 'swiper'

export default class SwiperWrapper {
  constructor (el, options) {
    this.el = el
    this.swiper = null
    this.extraElements = {
      navigation: [
        '.swiper-button-next',
        '.swiper-button-prev'
      ],
      pagination: ['.swiper-pagination'],
      scrollbar: ['.swiper-scrollbar']
    }
    this.waitingListeners = []

    this.setOptions(options || {})
    this.watchCSS()
    this.watchImageHeight()
    this.attemptInitSwiper()
  }

  setOptions (options) {
    const modules = [
      ...(options.navigation !== false ? [Navigation] : []),
      ...(options.effect ? [EffectFade] : []),
      ...(options.autoplay ? [Autoplay] : []),
      ...(options.pagination ? [Pagination] : []),
      ...(options.thumbs ? [Thumbs] : []),
      ...(options.scrollbar !== false ? [Scrollbar] : []),
      ...(options.mousewheel ? [Mousewheel] : [])
    ]

    this.options = {
      cssMode: true,
      modules,
      navigation: true,
      createElements: true,
      mousewheel: false,
      ...options
    }
  }

  watchCSS () {
    if (this.options.watchCSS) {
      window.addEventListener('resize', this.initOrDestroySwiper.bind(this))
    }
  }

  watchImageHeight () {
    if (this.options.watchImageHeight) {
      this.el.parentElement.classList.add('swiper-center-navigation-to-image')

      let imageEl = this.options.watchImageHeight

      if (typeof imageEl === 'string') {
        imageEl = this.el.querySelector(imageEl)
      }

      this.imageEl = imageEl
      this.updateImageHeightVariable()
      window.addEventListener('resize', this.updateImageHeightVariable.bind(this))
    }
  }

  updateImageHeightVariable () {
    if (this.imageEl) {
      this.el.parentElement.style.setProperty('--swiper-image-height', `${this.imageEl.clientHeight}px`)
    }
  }

  attemptInitSwiper () {
    if (!this.options.watchCSS) {
      this.initSwiper()
    } else {
      this.initOrDestroySwiper()
    }
  }

  initOrDestroySwiper () {
    const content = window.getComputedStyle(this.el, ':after').content.replace(/"/g, '')

    if (content === 'swiper') {
      this.initSwiper()
    } else {
      this.destroySwiper()
    }
  }

  async initSwiper () {
    if (!this.swiper) {
      this.updateNavigationOptions()
      await this.updateThumbsOptions()
      this.swiper = new Swiper(this.el, this.options)
      this.checkLocked()
      this.updateDotBlurPosition()
      this.waitingListeners.forEach(({ event, handler }) => {
        this.swiper.on(event, handler)
      })
      this.waitingListeners = []
    }
  }
  
  checkLocked () {
    if (this.swiper && this.el && this.swiper.isLocked) {
      this.el.classList.add('locked')
    }
    
    this.swiper.on('lock', () => {
      if (this.el) {
        this.el.classList.add('locked')
      }
    })
    
    this.swiper.on('unlock', () => {
      this.el.classList.remove('locked')
    })
  }

  updateDotBlurPosition () {
    if (this.options.pagination && this.options.pagination.type  && this.options.pagination.type === 'progressbar') {
      this.setWidthProgressBar()
      this.swiper.on('transitionEnd', () => {
        this.setWidthProgressBar()
      })
    }
  }

  setWidthProgressBar () {
    const progressBarEl = this.el.querySelector('.swiper-pagination-progressbar-fill')
    if (progressBarEl) {
      let width = 0
      if (this.swiper.slides.length) {
        if (this.swiper.progress == 1 || this.swiper.progress > 1) {
          width = 1
        } else {
          width = this.swiper.activeIndex > 0 ? (this.swiper.activeIndex + 1)/this.swiper.slides.length : 1/this.swiper.slides.length
        }
      }
      progressBarEl.style.width = `${width * 100}%`
    }
  }

  updateNavigationOptions () {
    if (this.options.navigationOutside && this.options.createElements) {
      const nextButton = document.createElement('div')
      nextButton.className = 'swiper-button-next'

      const prevButton = document.createElement('div')
      prevButton.className = 'swiper-button-prev'

      this.el.parentElement.appendChild(nextButton)
      this.el.parentElement.appendChild(prevButton)

      this.options.navigation = {
        ...(typeof this.options.navigation !== 'object' ? {} : this.options.navigation),
        nextEl: nextButton,
        prevEl: prevButton
      }
    }
  }

  async updateThumbsOptions () {
    return new Promise(resolve => {
      if (this.options.thumbs && typeof this.options.thumbs.swiper === 'string') {
        setTimeout(() => {
          const thumbsSwiperEl = document.querySelector(this.options.thumbs.swiper)

          if (thumbsSwiperEl && thumbsSwiperEl.swiper) {
            this.options.thumbs.swiper = thumbsSwiperEl.swiper
          }

          resolve()
        }, this.options.thumbs.waitTime || 200)
      } else {
        resolve()
      }
    })
  }

  on (event, handler) {
    if (this.swiper) {
      this.swiper.on(event, handler)
    } else {
      this.waitingListeners.push({
        event,
        handler
      })
    }
  }

  off (event, handler) {
    if (this.swiper) {
      this.swiper.off(event, handler)
    }
  }

  destroySwiper () {
    if (this.swiper) {
      this.swiper.destroy(true, true)
      this.swiper = null

      this.removeExtraElements()
    }
  }

  removeExtraElements () {
    const includedElements = this.options.includedElements || []

    for (const type in this.extraElements) {
      if (includedElements.includes(type)) {
        continue
      }

      this.removeElementsBySelector(this.extraElements[type].join(','))
    }
  }

  removeElementsBySelector (selector) {
    this.el.parentElement.querySelectorAll(selector)
      .forEach(element => element.remove())
  }
}
