const decode = str => decodeURIComponent(str).replace(/\+/g, ' ')

class CartItemRemove extends HTMLElement {
  constructor () {
    super()

    this.addDatalayer = this.addDatalayer.bind(this)
    const cart = this.closest('shopify-cart')
    const item = this.closest('cart-item')

    this.addEventListener('click', (event) => {
      event.preventDefault()
      cart.removeItem(item.dataset.key)
      this.addDatalayer()
    })
  }

  addDatalayer () {
    const item = this.closest('cart-item')
    const itemData = JSON.parse(decode(item.dataset.item))
    const itemCategories = JSON.parse(decode(item.getAttribute('data-categories')))
    const itemCategoriesString = itemCategories.toString().replace('[', '').replace(']', '')
    const index = (itemData.options_with_values || []).findIndex(option => option.name.toLowerCase() === 'color')
    const color = index > -1 ? itemData.options_with_values[index].value : ''
    const itemListName = item.getAttribute('data-item-list')
    const itemListId = itemListName.toLocaleLowerCase().replace(' ', '_')
    Shopify.analytics.publish('custom_remove_from_cart', {
      ecommerce: {
        items: [
          {
            item_id: itemData.sku,
            item_name: itemData.product_title,
            affiliation: "pavise",
            currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
            discount: itemData.discounts[0],
            index: 0,
            item_brand: itemData.vendor,
            item_category: itemCategoriesString,
            item_variant: color,
            item_list_id: itemListId,
            item_list_name: itemListName,
            price: (parseFloat(itemData.price) / 100).toFixed(2).toString(),
            quantity: itemData.quantity
          }
        ]
      }
    })
  }
}

if (!customElements.get('cart-item-remove')) customElements.define('cart-item-remove', CartItemRemove)
